import PropTypes from 'prop-types'
import {
  DialogActions,
  DialogContent,
  InputAdornment,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
} from '@mui/material'
// /*eslint-disable*/
import * as Yup from 'yup'
import React, { useRef } from 'react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import NoContent from '../../pages/NoContent'
import LoadingButton from '@mui/lab/LoadingButton'
import { refreshFilter } from '../../../../lib/handleFilters'
import { handleOpenAlert } from '../../../../lib/handleAlert'
import SaveIcon from '@mui/icons-material/Save'
import { Add, Clear, Delete, Refresh } from '@mui/icons-material'
import { closePopup } from '../../../../store/popupsSlice'
import { useDispatch } from 'react-redux'
import Input from 'components/modules/Input/atoms/Input'
import Autocomplete from 'components/modules/Input/atoms/Autocomplete'
import { TableVirtuoso } from 'react-virtuoso'
import VirtusoTableComponentsConfig from 'features/Virtuoso/config/VirtusoTableComponentsConfig'
import MaterialFinishingPricingListHeaderForm from 'components/modules/MaterialPricing/molecules/MaterialFinishingPricingListHeaderForm'
import MaterialPricingListHeaderForm from 'components/modules/MaterialPricing/molecules/MaterialPricingListHeaderForm'
import MaterialPricingListItemEditForm from 'components/modules/MaterialPricing/molecules/MaterialPricingListItemEditForm'
import MaterialPricingListItemForm from 'components/modules/MaterialPricing/molecules/MaterialPricingListItemForm'
import MaterialPricingListFinishingItemForm from 'components/modules/MaterialPricing/molecules/MaterialPricingListFinishingItemForm'
import numberParser from 'features/Validation/functions/numberParser'
import { useTranslation } from 'react-i18next'
import ItemsValidation from 'features/Validation/hooks/ItemsValidation'
import LoadingText from 'components/atoms/LoadingText'
import { toFixedNumber } from 'lib/numberFormatter'
import UnitEnum from '../../../../features/Unit/Enum/UnitEnum'
import {
  calculatePriceNet,
  calculateWholesalePriceNet,
} from '../../../../features/MaterialPricing/materialPricesCalculation'

const MaterialPricingFactory = ({ ...values }) => {
  const virtuoso = useRef()

  const materialId = values.id
  const priceListId = values.priceListId
  const finishing = !!values.finishing

  const { t } = useTranslation()

  const [priceList, setPriceList] = useState({})
  const [colors, setColors] = useState([])
  const [items, _setItems] = useState([])
  const [material, setMaterial] = useState(null)
  const [lastDiscount, setLastDiscount] = useState(0)
  const [timeoutValue, setTimeoutValue] = useState(null)
  const [thicknessId, setThicknessId] = useState(null)
  const [thickness, setThickness] = useState(null)
  const [extras, _setExtras] = useState(null)
  const [names, setNames] = useState(null)
  const [toDel, setToDel] = useState([])
  const [errorsPerItem, setErrorsPerItem] = useState({})
  const [searchValue] = useState('')
  const [calculating, setCalculating] = useState(false)
  const [priceChange, setPriceChange] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetchingData, setFetchingData] = useState(true)
  const popupKey = values.popupKey

  const dispatch = useDispatch()

  const changePrices = (value) => {
    if (timeoutValue) {
      clearTimeout(timeoutValue)
    }
    setTimeoutValue(
      setTimeout(() => {
        calculateRows(value)
      }, 500)
    )
    setPriceChange(value)
  }

  useEffect(() => {
    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [items])

  useEffect(() => {
    axios
      .get(`/price_lists/${priceListId}`)
      .then(({ data }) => setPriceList(data))
  }, [priceListId])

  const listener = (event) => {
    const types = ['wholesale_price_net', 'increase_price', 'price_net']
    let index
    let type
    let typeIndex
    if (
      ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      event.preventDefault()
      const id = event.target.id.split('-')
      type = id[0]
      typeIndex = types.findIndex((typeValue) => typeValue === type)
      index = +id[1]
    }
    let newType
    let newIndex
    if (event.key === 'ArrowDown') {
      newType = type
      newIndex = (index + 1) % items.length
    } else if (event.key === 'ArrowUp') {
      newType = type
      index === 0 ? (newIndex = items.length - 1) : (newIndex = index - 1)
    } else if (event.key === 'ArrowLeft') {
      newIndex = index
      if (typeIndex !== 0) {
        newType = types[typeIndex - 1]
      }
    } else if (event.key === 'ArrowRight') {
      newIndex = index
      if (typeIndex !== types.length - 1) {
        newType = types[typeIndex + 1]
      }
    }
    if (newType !== undefined && newIndex !== undefined) {
      virtuoso.current.scrollToIndex({
        index: newIndex,
        align: 'center',
      })
      setTimeout(() => {
        const element = document.getElementById(`${newType}-${newIndex}`)
        if (element) {
          element.focus()
        }
      }, 100)
    }
  }

  let filteredItems = items
  if (searchValue) {
    filteredItems = items.filter((item) => item.name.includes(searchValue))
  }

  const itemRules = Yup.object({
    color_id: Yup.number()
      .transform(numberParser)
      .required(t('main.validation.required'))
      .min(0, t('main.validation.min_value', { min: 0 })),
    name: Yup.string().when('color_id', {
      is: (value) => value && finishing,
      then: Yup.string().required(t('main.validation.required')),
    }),
    extra_id: Yup.mixed().when('color_id', {
      is: (value) => value && finishing,
      then: Yup.number()
        .transform(numberParser)
        .required(t('main.validation.required')),
    }),
    thickness_id: Yup.mixed().when('color_id', {
      is: (value) =>
        !finishing && value && material.unit_id === UnitEnum.SQUARE_METERS,
      then: Yup.number()
        .transform(numberParser)
        .required(t('main.validation.required')),
    }),
    width: Yup.mixed().when('color_id', {
      is: (value) =>
        value && !finishing && material.unit_id === UnitEnum.LINEAR_METERS,
      then: Yup.number()
        .transform(numberParser)
        .required(t('main.validation.required'))
        .min(0, t('main.validation.min_value', { min: 0 })),
    }),
    price_net: Yup.mixed().when('color_id', {
      is: (value) => value,
      then: Yup.number()
        .transform(numberParser)
        .required(t('main.validation.required'))
        .min(0, t('main.validation.min_value', { min: 0 })),
    }),
  })

  const [validateItems] = ItemsValidation(itemRules, items)

  const addItem = () => {
    items.push({ finishing, key: Math.random(), increase_price_net: 0 })
    setItems(items)
    scrollBottom()
  }

  const resetPrices = () => {
    setPriceChange(0)
    setLastDiscount(0)
    for (const item of items) {
      if (!item.main_price_net) {
        continue
      }
      item.price_net = item.main_price_net
      if (item.increase_price_net) {
        item.wholesale_price_net = calculateWholesalePriceNet(
          item.main_price_net,
          item.increase_price_net
        )
      }
    }
    setItems(items)
  }

  const scrollBottom = () => {
    const container = document.getElementById('virtuso-table-component')
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 5)
  }

  const setItems = (items) => {
    _setItems([...items])
  }

  const calculateRows = (p) => {
    setCalculating(true)
    const type =
      !finishing && material && material.unit_id === UnitEnum.LINEAR_METERS
        ? 'wholesale_price_net'
        : 'price_net'
    for (const item of items) {
      if (item[type]) {
        item[type] = parseFloat(
          (item[type] / (1 + lastDiscount / 100)) * (1 + p / 100)
        ).toFixed(2)
        if (item.increase_price_net) {
          item.price_net = calculatePriceNet(
            item.wholesale_price_net,
            item.increase_price_net
          )
        }
      }
    }
    setLastDiscount(p)
    setItems(items)
    setCalculating(false)
  }
  const loadColors = (id) => {
    return new Promise(function (resolve) {
      axios.post(`/materials/${id}/colors/filter`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }

  const loadMaterial = (id) => {
    return new Promise(function (resolve) {
      axios.post(`/materials/${id}`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }

  const getItemIndex = (key) => {
    return items.findIndex((i) => i.key == key)
  }

  const updateItem = (item) => {
    const index = getItemIndex(item.key)
    items[index] = item
    setItems(items)
  }

  const deleteItem = (item) => {
    const index = getItemIndex(item.key)
    items.splice(index, 1)
    if (item.id) {
      toDel.push(item.id)
      setToDel([...toDel])
    }
    setItems(items)
  }

  const loadThickness = () => {
    return new Promise(function (resolve) {
      axios.post(`/lookups/thickness`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }
  const loadExtras = () => {
    return new Promise(function (resolve) {
      axios.get(`/materials/${materialId}/extras`).then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
    })
  }
  const loadItems = (material) => {
    axios
      .post(
        finishing
          ? `/price_lists/${priceListId}/materials/${material.id}/extra_prices/filter`
          : `/price_lists/${priceListId}/materials/${material.id}/prices`,
        {
          filters: values.filters ? values.filters.filters : null,
        }
      )
      .then((res) => {
        let items = res.data
        if (!finishing && material.unit_id === UnitEnum.LINEAR_METERS) {
          setThicknessId(items[0] ? items[0].thickness_id : null)
        }
        if (items.length) {
          setItems(
            items.map((item) => ({
              ...item,
              increase_price_net: Math.round(
                item.wholesale_price_net
                  ? toFixedNumber({
                      value:
                        (+item.price_net / +item.wholesale_price_net) * 100 -
                        100,
                    })
                  : 0
              ),
              finishing,
              key: Math.random(),
              extra_id: finishing ? item.id : null,
            }))
          )
        } else {
          addItem()
        }
      })
  }

  const setExtras = async () => {
    let extras = await loadExtras()
    let names = []
    let keys = Object.keys(extras)
    for (let i = 0; i < keys.length; i++) {
      names.push({ name: keys[i] })
    }
    setNames(names)
    _setExtras(extras)
  }

  useEffect(async () => {
    if (materialId) {
      setLoading(true)
      setFetchingData(true)
      loadMaterial(materialId).then(async (material) => {
        setMaterial(material)
        setColors(await loadColors(materialId))
        setThickness(await loadThickness())
        if (finishing) {
          setExtras()
        }
        loadItems(material)
        setLoading(false)
        setFetchingData(false)
      })
    }
  }, [materialId])

  useEffect(async () => {
    if (material) {
      if (finishing) {
        setExtras()
      }
    }
  }, [material])

  const validateAndSave = () => {
    if (
      !finishing &&
      material.unit_id === UnitEnum.LINEAR_METERS &&
      !thicknessId
    ) {
      handleOpenAlert({
        title: 'Błąd!',
        subtitle: 'Grubość jest wymagana',
        type: 'warning',
      })
      return
    }
    const filteredItems = items.filter((item) => item.color_id)
    setLoading(true)
    validateItems(itemRules, filteredItems)
      .then(async () => {
        return save(filteredItems)
      })
      .catch((errors) => {
        setErrorsPerItem(errors)
        virtuoso.current.scrollToIndex({
          index: getItemIndex(Object.keys(errors)[0]),
          align: 'start',
          behavior: 'smooth',
        })
        handleOpenAlert({
          title: 'Błąd!',
          subtitle: 'Uzupełnij brakujące pola ',
          type: 'warning',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const save = (items) => {
    return axios
      .post(
        `/price_lists/${priceListId}/materials/${materialId}/${
          finishing ? 'extras' : 'prices'
        }/edit`,
        {
          prices: items,
          to_del: toDel,
          ...(!finishing && material.unit_id === UnitEnum.LINEAR_METERS
            ? { thickness_id: thicknessId }
            : {}),
        }
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          refreshFilter(values.filterKey)
          dispatch(closePopup(values.popupKey))
        }
        handleOpenAlert({
          title: 'Sukces!',
          subtitle: 'Zaktualizowano cennik ',
        })
      })
      .catch((err) => {
        let errors = err.response.data.errors
        let key = Object.keys(errors)[0]
        let index = key.split('.')[1]
        virtuoso.current.scrollToIndex({
          index,
          align: 'start',
          behavior: 'smooth',
        })
        let el = document.querySelector(`[data-index="${index}"]`)
        if (el) {
          el.className = 'error-background'
          setTimeout(() => {
            el.className = ''
          }, 3000)
        }
        handleOpenAlert({
          title: 'Pole posiada zduplikowaną wartość',
          type: 'warning',
        })
        return err
      })
  }

  const action = (item, index) => {
    return (
      <div>
        {items.length > 1 && (
          <IconButton
            color="error"
            component="span"
            size="large"
            onClick={() => {
              deleteItem(item)
            }}
          >
            <Delete />
          </IconButton>
        )}
        {items.length - 1 == index && (
          <IconButton
            component="span"
            size="large"
            onClick={() => {
              addItem()
            }}
          >
            <Add />
          </IconButton>
        )}
      </div>
    )
  }

  return (
    <>
      <Dialog
        open={true}
        fullWidth={true}
        fullScreen
        style={{ zIndex: 1300 }}
        scroll="paper"
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="div">
              Edytuj cennik <b>{priceList.name}</b>
            </Typography>
            <Clear
              className="cursor-pointer"
              onClick={() => dispatch(closePopup(popupKey))}
            />
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid
            container
            spacing={3}
            style={{ gridTemplateRows: 'auto auto 100%', display: 'grid' }}
          >
            <Grid item xs={12}>
              <Input
                disabled={calculating}
                labelName={`Zwiększ cenę ${
                  !finishing
                    ? material && material.unit_id === UnitEnum.LINEAR_METERS
                      ? 'hurtową'
                      : 'detaliczną'
                    : ''
                } o:`}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                onChange={changePrices}
                value={priceChange}
              />
              {calculating && <LoadingText text="Kalkulowanie" />}
            </Grid>
            {material &&
              !finishing &&
              material.unit_id === UnitEnum.LINEAR_METERS && (
                <Grid item xs={12}>
                  <Autocomplete
                    labelName="Grubość"
                    value={thicknessId}
                    options={thickness}
                    onChange={setThicknessId}
                  />
                </Grid>
              )}
            <Grid style={{ height: '70vh' }} item xs={12}>
              {items.length ? (
                <TableVirtuoso
                  data={filteredItems}
                  ref={virtuoso}
                  components={VirtusoTableComponentsConfig}
                  style={{ height: '100%' }}
                  fixedHeaderContent={() =>
                    finishing ? (
                      <MaterialFinishingPricingListHeaderForm
                        style={{ backgroundColor: '#fff' }}
                      />
                    ) : (
                      <MaterialPricingListHeaderForm
                        style={{ backgroundColor: '#fff' }}
                        material={material}
                      />
                    )
                  }
                  itemContent={(index, item) =>
                    React.createElement(
                      item.id
                        ? MaterialPricingListItemEditForm
                        : finishing
                        ? MaterialPricingListFinishingItemForm
                        : MaterialPricingListItemForm,
                      {
                        item,
                        material,
                        colors,
                        thickness,
                        finishing,
                        extras,
                        names,
                        errors: errorsPerItem[item.key] || {},
                        index,
                        updateItem,
                        action: action(item, index),
                      }
                    )
                  }
                />
              ) : (
                <NoContent loading={fetchingData} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <>
            <LoadingButton
              startIcon={<Refresh />}
              color="primary"
              variant="contained"
              type="button"
              onClick={resetPrices}
              size="large"
              className="mr-2"
            >
              <span className="mt-1">Reset procentu ceny głównej do 100%</span>
            </LoadingButton>
            <LoadingButton
              startIcon={<Add />}
              color="primary"
              variant="contained"
              type="button"
              onClick={addItem}
              size="large"
              className="mr-2"
            >
              <span className="mt-1">Dodaj</span>
            </LoadingButton>
            <LoadingButton
              color="primary"
              startIcon={<SaveIcon />}
              variant="contained"
              onClick={validateAndSave}
              loading={loading}
              type="button"
              size="large"
            >
              <span className="mt-1">Zapisz</span>
            </LoadingButton>
          </>
        </DialogActions>
      </Dialog>
    </>
  )
}

MaterialPricingFactory.propTypes = {
  values: PropTypes.object,
  popupKey: PropTypes.string,
}

export default MaterialPricingFactory
